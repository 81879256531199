define('mediasuite-formelements/components/mediasuite-check-boxes-group', ['exports', 'ember'], function (exports, _ember) {
  var set = _ember['default'].set;
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['check-boxes-group'],
    classNameBindings: ['useButtonStyleCheckboxes:rounded-wrapper', 'useButtonStyleCheckboxes:margin-btm-20'],
    actions: {
      updateGroup: function updateGroup(group) {
        // Set internal checkboxes to the state of the parent checkbox
        var groupChecked = this.get('group.checked');
        var groupOptions = this.get('group.options');
        groupOptions.forEach(function (option) {
          set(option, 'checked', groupChecked);
        });
        // Hack because computed properties with @each broken
        // @see https://github.com/emberjs/ember.js/issues/12475
        this.set('group.options', JSON.parse(JSON.stringify(groupOptions)));
        this.get('update')();
      },
      updateOptions: function updateOptions(checkedValues) {
        // If all checkboxes checked, set parent to checked
        // If not all, set parent to unchecked
        var groupOptions = this.get('group.options');
        var allChecked = true;
        groupOptions.forEach(function (option) {
          if (checkedValues.indexOf(option.value) < 0) {
            set(option, 'checked', false);
            allChecked = false;
          } else {
            set(option, 'checked', true);
          }
        });
        this.set('group.checked', allChecked);
        this.get('update')();
      }
    }
  });
});
/**
 * Creates a group of checkboxes, each of which is contained by a parent checkbox
 * that will tick/untick all of its children
 * @param group  {Object}   An object containing the following properties:
 *                           - name     {String}
 *                           - checked  {Boolean} if this entire group should be checked
 *                           - disabled {Boolean}
 *                           - options  {Array}   An array of checkbox options to pass to the check-boxes component
 * @param update {Function} Update to be called when the group object has been updated
 */