define('mediasuite-formelements/components/mediasuite-file-upload', ['exports', 'ember', 'tmp-for-all/constants'], function (exports, _ember, _tmpForAllConstants) {
  var get = _ember['default'].get;
  var $ = _ember['default'].$;
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'span',
    maxFileSize: _tmpForAllConstants.MAX_FILE_SIZE,
    acceptFilter: 'application/pdf,image/*',
    actions: {
      didSelectFiles: function didSelectFiles() {
        var selection = [];
        var files = this.$('input')[0].files;
        for (var i = 0; i < files.length; i++) {
          selection.pushObject(files[i]);
        }
        $('input').val('');
        get(this, 'addFiles')(selection);
      }
    }
  });
});