define('mediasuite-formelements/components/mediasuite-radio-buttons-boolean', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  exports['default'] = _ember['default'].Component.extend({
    name: '',
    computedValue: computed('value', 'options', function () {
      var value = get(this, 'value');
      return value == null ? null : get(this, 'options').find(function (option) {
        return option.value === value;
      });
    }),
    options: computed('value', 'disabled', function () {
      return [{
        value: true,
        label: 'Yes',
        disabled: get(this, 'disabled')
      }, {
        value: false,
        label: 'No',
        disabled: get(this, 'disabled')
      }];
    }),
    actions: {
      onChange: function onChange(selectedValue) {
        get(this, 'update')(get(selectedValue, 'value'));
      }
    }
  });
});