define('mediasuite-formelements/components/mediasuite-file-upload/item', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'li',
    classNames: ['file-item', 'file-to-upload'],
    classNameBindings: ['fileExceedsMaxSize:oversized-file'],
    fileExceedsMaxSize: computed('file.size', 'maxFileSize', function () {
      var fileSize = get(this, 'file.size');
      var maxFileSize = get(this, 'maxFileSize');
      if (!maxFileSize) return false;
      return fileSize > maxFileSize;
    }),
    isImage: computed('file', function () {
      return (/image\/.*/.test(get(this, 'file.type'))
      );
    })
  });
});