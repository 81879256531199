define('mediasuite-formelements/components/mediasuite-radio-buttons', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['element-group-wrap'],
    classNameBindings: ['useButtonStyle:button-style-radios'],
    radioButtonsName: computed('name', function () {
      return get(this, 'name') || get(this, 'elementId');
    })
  });
});