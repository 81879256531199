define('mediasuite-formelements/components/mediasuite-check-boxes', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'fieldset',
    classNames: ['check-boxes', 'group-element-wrap'],
    selected: computed.filterBy('options', 'checked', true),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      set(this, 'clonedOptions', JSON.parse(JSON.stringify(get(this, 'options'))));
    },
    actions: {
      onUpdate: function onUpdate(option, isChecked) {
        set(option, 'checked', isChecked);
        get(this, 'update')(get(this, 'clonedOptions'), option, isChecked);
      }
    }
  });
});