define('mediasuite-formelements/components/mediasuite-select-box', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var set = _ember['default'].set;
  var get = _ember['default'].get;
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['element-wrap'],
    classNameBindings: ['multiple:multiple'],
    inputId: computed('elementId', function () {
      return 'input-' + this.get('elementId');
    }),
    actions: {
      update: function update(value) {
        if (value === undefined) value = null;
        set(this, 'mutField', value);
      }
    }
  });
});