define('mediasuite-formelements/components/mediasuite-radio-button', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['element-wrap', 'radio-button'],
    classNameBindings: ['isChecked'],
    inputId: computed('elementId', function () {
      return 'input-' + get(this, 'elementId');
    }),
    disabled: false,
    isDisabled: computed('disabled', function () {
      return get(this, 'disabled') ? 'disabled' : '';
    }),
    isChecked: computed('value', 'option', function () {
      return get(this, 'value') === get(this, 'option');
    }),
    value: ''
  });
});