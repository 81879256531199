define("ember-key-manager/services/key-manager", ["exports", "ember-key-manager/utils/macro", "ember-key-manager/utils/modifier-keys", "ember-key-manager/utils/warning-messages"], function (_exports, _macro, _modifierKeys, _warningMessages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inputElements = ['INPUT', 'SELECT', 'TEXTAREA'];
  var isInputElement = function isInputElement(element) {
    var isContentEditable = element.isContentEditable;
    var isInput = inputElements.includes(element.tagName);
    return isContentEditable || isInput;
  };
  var _default = Ember.Service.extend({
    isDisabledOnInput: false,
    // Config option

    keydownMacros: Ember.computed.filterBy('macros', 'keyEvent', 'keydown'),
    keyupMacros: Ember.computed.filterBy('macros', 'keyEvent', 'keyup'),
    init: function init() {
      this._super.apply(this, arguments);
      this.macros = Ember.A();
      this._registerConfigOptions();
    },
    addMacro: function addMacro(options) {
      var macroAttrs = this._mergeConfigDefaults(options);
      var macro = _macro.default.create();
      macro.setup(macroAttrs);
      var keyEvent = Ember.get(macro, 'keyEvent');
      this._handleModifiersOnKeyup(macro, keyEvent);
      var element = Ember.get(macro, 'element');
      this._addEventListener(element, keyEvent);
      var macros = Ember.get(this, 'macros');
      macros.pushObject(macro);
      return macro;
    },
    _handleModifiersOnKeyup: function _handleModifiersOnKeyup(_ref, keyEvent) {
      var modifierKeys = _ref.modifierKeys;
      if (keyEvent === 'keyup' && Ember.isPresent(modifierKeys)) {
        (false && Ember.warn(_warningMessages.MODIFIERS_ON_KEYUP, false, {
          id: 'keyup-with-modifiers'
        }));
      }
    },
    _mergeConfigDefaults: function _mergeConfigDefaults(attrs) {
      var isDisabledOnInput = Ember.get(this, 'isDisabledOnInput');
      return Ember.assign({
        isDisabledOnInput: isDisabledOnInput
      }, attrs);
    },
    _addEventListener: function _addEventListener(element, keyEvent) {
      var hasListenerForElementAndKeyEvent = this._findMacroWithElementAndKeyEvent(element, keyEvent);
      if (!hasListenerForElementAndKeyEvent) {
        element.addEventListener(keyEvent, this);
      }
    },
    removeMacro: function removeMacro(macro) {
      var element = Ember.get(macro, 'element');
      var keyEvent = Ember.get(macro, 'keyEvent');
      var macros = Ember.get(this, 'macros');
      macros.removeObject(macro);
      this._removeEventListenter(element, keyEvent);
    },
    _removeEventListenter: function _removeEventListenter(element, keyEvent) {
      var hasListenerForElementAndKeyEvent = this._findMacroWithElementAndKeyEvent(element, keyEvent);
      if (!hasListenerForElementAndKeyEvent) {
        element.removeEventListener(keyEvent, this);
      }
    },
    disable: function disable(recipient) {
      this._setDisabledState(recipient, true);
    },
    enable: function enable(recipient) {
      this._setDisabledState(recipient, false);
    },
    handleEvent: function handleEvent(event) {
      if (Ember.get(this, 'isDestroyed') || Ember.get(this, 'isDestroying')) {
        return false;
      }
      var isKeydown = event.type === 'keydown';
      var isKeyup = event.type === 'keyup';
      if (isKeydown || isKeyup) {
        var allEventModifierKeys = {
          altKey: event.altKey,
          ctrlKey: event.ctrlKey,
          metaKey: event.metaKey,
          shiftKey: event.shiftKey
        };
        var eventModifierKeys = Ember.A(Object.keys(allEventModifierKeys).filter(function (key) {
          return allEventModifierKeys[key] !== false;
        }));
        var matchingMacros = this._findMatchingMacros(event.target, event.key || '', eventModifierKeys, event.type);
        if (Ember.isPresent(matchingMacros)) {
          var isTargetInput = isInputElement(event.target);
          event.stopPropagation();
          matchingMacros.forEach(function (matchingMacro) {
            var isDisabled = Ember.get(matchingMacro, 'isDisabled') || Ember.get(matchingMacro, 'isDisabledOnInput') && isTargetInput;
            if (!isDisabled) {
              Ember.get(matchingMacro, 'callback')(event);
            }
          });
        }
      }
    },
    _findMacroWithElementAndKeyEvent: function _findMacroWithElementAndKeyEvent(eventElement, eventKeyEvent) {
      return Ember.get(this, "".concat(eventKeyEvent, "Macros")).find(function (macro) {
        var element = Ember.get(macro, 'element');
        return eventElement === element;
      });
    },
    _findMatchingMacros: function _findMatchingMacros(eventElement, eventExecutionKey, eventModifierKeys, eventKeyEvent) {
      var matchingMacros = Ember.get(this, "".concat(eventKeyEvent, "Macros")).filter(function (macro) {
        var _Ember$getProperties = Ember.getProperties(macro, ['element', 'executionKey', 'modifierKeys']),
          element = _Ember$getProperties.element,
          executionKey = _Ember$getProperties.executionKey,
          modifierKeys = _Ember$getProperties.modifierKeys;
        var hasElementMatch = element === eventElement || element.contains(eventElement);
        var hasExecutionKeyMatch = eventExecutionKey.toLowerCase() === executionKey.toLowerCase();
        var hasModifierKeysMatch = eventModifierKeys.removeObject(_modifierKeys.TO_MODIFIER[eventExecutionKey]).every(function (key) {
          return modifierKeys.toArray().map(function (k) {
            return Ember.String.capitalize(k);
          }).includes(_modifierKeys.TO_KEY[key]);
        });
        var hasModifierKeyCount = eventModifierKeys.length === modifierKeys.length;
        return hasElementMatch && hasExecutionKeyMatch && hasModifierKeysMatch && hasModifierKeyCount;
      });
      var highestPriority = Ember.A(matchingMacros).mapBy('priority').reduce(function (max, priority) {
        return Math.max(max, priority);
      }, -Infinity);
      return matchingMacros.filter(function (macro) {
        return Ember.get(macro, 'priority') === highestPriority;
      });
    },
    _registerConfigOptions: function _registerConfigOptions() {
      var config = Ember.getOwner(this).lookup('main:key-manager-config');
      if (config) {
        Ember.setProperties(this, config);
      }
    },
    _setDisabledState: function _setDisabledState(recipient, isDisabled) {
      if (typeof recipient === 'string') {
        this._setGroupDisabledState(recipient, isDisabled);
      } else {
        Ember.set(recipient, 'isDisabled', isDisabled);
      }
    },
    _setGroupDisabledState: function _setGroupDisabledState(groupName, isDisabled) {
      Ember.get(this, 'macros').filterBy('groupName', groupName).setEach('isDisabled', isDisabled);
    }
  });
  _exports.default = _default;
});