define('mediasuite-formelements/components/mediasuite-currency-field', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['currency-field'],
    inputID: computed('elementId', function () {
      return 'input-' + get(this, 'elementId');
    }),
    value: null,
    init: function init() {
      this._super.apply(this, arguments);
      var initialValue = (get(this, 'initialValue') / 100).toFixed(2);
      set(this, 'value', initialValue);
    },

    actions: {
      update: function update(value) {
        if (!value) return get(this, 'update')(0);
        get(this, 'update')(value * 100);
      },
      toFixed: function toFixed(value) {
        return parseFloat(value, 10).toFixed(2);
      }
    }
  });
});