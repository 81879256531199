define("ember-key-manager/utils/macro", ["exports", "ember-copy"], function (_exports, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var defaultAttrs = {
    callback: null,
    element: document.body,
    executionKey: '',
    isDisabledOnInput: false,
    modifierKeys: [],
    priority: 0,
    keyEvent: null,
    groupName: null,
    isDisabled: false
  };
  var _default = Ember.Object.extend({
    setup: function setup(customAttrs) {
      var defaultAttrsCopy = (0, _emberCopy.copy)(defaultAttrs);
      var attrs = Ember.assign(defaultAttrsCopy, customAttrs);
      if (attrs.modifierKeys !== undefined) {
        attrs.modifierKeys = Ember.A(attrs.modifierKeys);
      }
      Ember.setProperties(this, attrs);
    }
  });
  _exports.default = _default;
});